var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 h-100 px-6 py-8 d-flex flex-column justify-content-between bg-white rounded-lg"},[_c('div',[_c('div',{staticClass:"w-100 symbol symbol-150 d-flex justify-center"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.connector.image,"alt":"item image"}})]),_c('h5',{staticClass:"my-8 text-dark-50 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.connector.name)+" ")])]),_c('div',{staticClass:"w-100 d-flex align-center justify-space-between"},[_c('button',{staticClass:"btn btn-sm btn-info px-5 py-3 ls1",on:{"click":() =>
          _vm.connector.is_active
            ? (_vm.$refs.titleValueListModal.dialog = true)
            : _vm.$refs.actionsDialog.toggleModal()}},[_vm._v(" "+_vm._s(_vm.connector.is_active ? _vm.$t('see-details') : _vm.$t('connect'))+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"rounded-circle h-10px w-10px",class:[_vm.connector.is_active ? 'bg-success' : 'bg-danger']}),_c('span',{staticClass:"mx-2 mb-0 text-dark-50 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.connector.is_active ? _vm.$t('active') : _vm.$t('inactive'))+" ")])])]),_c('ActionsDialog',{ref:"actionsDialog",attrs:{"connector_id":_vm.connector.id,"customer_id":_vm.customerId}}),_c('TitleValueListModal',{ref:"titleValueListModal",attrs:{"data":_vm.connector.credentials,"triggerActionDialogForEdit":() => {
        _vm.$refs.actionsDialog.toggleModal(
          _vm.connector.credentials,
          _vm.connector.code
        );
        _vm.$refs.titleValueListModal.dialog = false;
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }