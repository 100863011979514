<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column max-h-700px scroll-y mx-5 py-7">
        <form class="row">
          <div class="col-12 col-sm-6 h-75px" v-if="code === 'LSR'">
            <v-text-field
              v-model="formData.rcs_ip"
              :label="$t('rcs-ip')"
              dense
              outlined
              clearable
              :error-messages="rcs_ipErrors"
              @blur="$v.formData.rcs_ip.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="code === 'LSR'">
            <v-text-field
              v-model="formData.rcs_port"
              :label="$t('rcs-port')"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              :error-messages="rcs_portErrors"
              @blur="$v.formData.rcs_port.$touch()"
              @change="() => validateMinValue('rcs_port', 0)"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="code === 'SR'">
            <v-text-field
              v-model="formData.wcs_ip"
              :label="$t('wcs-ip')"
              dense
              outlined
              clearable
              :error-messages="wcs_ipErrors"
              @blur="$v.formData.wcs_ip.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="code === 'SR'">
            <v-text-field
              v-model="formData.wcs_port"
              :label="$t('wcs-port')"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              :error-messages="wcs_portErrors"
              @blur="$v.formData.wcs_port.$touch()"
              @change="() => validateMinValue('wcs_port', 0)"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.wes_ip"
              :label="$t('wes-ip')"
              dense
              outlined
              clearable
              :error-messages="wes_ipErrors"
              @blur="$v.formData.wes_ip.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.wes_port"
              :label="$t('wes-port')"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              :error-messages="wes_portErrors"
              @blur="$v.formData.wes_port.$touch()"
              @change="() => validateMinValue('wes_port', 0)"
            />
          </div>

          <div class="col-12 h-75px" :class="{ 'col-md-6': code === 'LSR' }">
            <v-text-field
              v-model="formData.number_of_chutes"
              :label="$t('number-of-chutes')"
              type="number"
              :min="1"
              dense
              outlined
              clearable
              :error-messages="number_of_chutesErrors"
              @blur="$v.formData.number_of_chutes.$touch()"
              @change="() => validateMinValue('number_of_chutes', 1)"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="code === 'LSR'">
            <v-text-field
              v-model="formData.number_of_robots"
              :label="$t('number-of-robots')"
              type="number"
              :min="1"
              dense
              outlined
              clearable
              :error-messages="number_of_robotsErrors"
              @blur="$v.formData.number_of_robots.$touch()"
              @change="() => validateMinValue('number_of_robots', 1)"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="code === 'SR'">
            <v-text-field
              v-model="formData.wcs_print_ip"
              :label="$t('wcs-print-ip')"
              dense
              outlined
              clearable
              :error-messages="wcs_print_ipErrors"
              @blur="$v.formData.wcs_print_ip.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="code === 'SR'">
            <v-text-field
              v-model="formData.wcs_print_port"
              :label="$t('wcs-print-port')"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              :error-messages="wcs_print_portErrors"
              @blur="$v.formData.wcs_print_port.$touch()"
              @change="() => validateMinValue('wcs_print_port', 0)"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="code === 'LSR'">
            <v-autocomplete
              v-model="formData.tile_type"
              :label="$t('tile-type')"
              :items="tile_types"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="tile_typeErrors"
              @blur="$v.formData.tile_type.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="code === 'LSR'">
            <v-text-field
              v-model="formData.tile_value"
              :label="$t('tile-value')"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              :error-messages="tile_valueErrors"
              @blur="$v.formData.tile_value.$touch()"
              @change="() => validateMinValue('tile_value', 0)"
            />
          </div>

          <div class="col-6 col-sm-6 col-md-12 h-75px" v-if="code === 'LSR'">
            <v-text-field
              v-model="formData.mode"
              :label="$t('mode')"
              dense
              outlined
              clearable
            />
          </div>
        </form>

        <!--begin::Actions-->
        <div class="mb-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light me-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            {{ $t("clear") }}
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import validationMixin from "@/own/mixins/validationMixin";
import { required, requiredIf } from "vuelidate/lib/validators";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation";
import { GET_WES_CUSTOMERS } from "@/core/services/store/wes.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import SwalService from "@/core/services/swal.service";

export default {
  name: "ActionsDialog",
  mixins: [validationMixin, fieldValueValidation],
  props: {
    connector_id: { required: true, type: Number },
    customer_id: { required: true, type: Number },
  },
  validations: {
    formData: {
      rcs_ip: {
        required: requiredIf(function () {
          return this.code === "LSR";
        }),
      },
      rcs_port: {
        required: requiredIf(function () {
          return this.code === "LSR";
        }),
      },
      wes_ip: { required },
      wes_port: { required },
      number_of_chutes: { required },
      number_of_robots: {
        required: requiredIf(function () {
          return this.code === "LSR";
        }),
      },
      tile_type: {
        required: requiredIf(function () {
          return this.code === "LSR";
        }),
      },
      tile_value: {
        required: requiredIf(function () {
          return this.code === "LSR";
        }),
      },
      wcs_ip: {
        required: requiredIf(function () {
          return this.code === "SR";
        }),
      },
      wcs_port: {
        required: requiredIf(function () {
          return this.code === "SR";
        }),
      },
      wcs_print_ip: {
        required: requiredIf(function () {
          return this.code === "SR";
        }),
      },
      wcs_print_port: {
        required: requiredIf(function () {
          return this.code === "SR";
        }),
      },
    },
  },
  data: () => ({
    dialog: false,
    code: "", // rcs_ip or wcs_ip
    formData: {
      rcs_ip: null,
      rcs_port: null,
      wes_ip: null,
      wes_port: null,
      number_of_chutes: null,
      number_of_robots: null,
      mode: null,
      tile_type: null,
      tile_value: null,
      // -------------
      wcs_ip: null,
      wcs_port: null,
      wcs_print_ip: null,
      wcs_print_port: null,
      // "wes_ip" : null,
      // "wes_port" : null,
      // "number_of_chutes" : null,
    },
  }),
  methods: {
    toggleModal(credentials = null, code) {
      if (this.dialog) {
        this.resetFormData();
      } else {
        if (credentials) {
          this.code = code;

          this.$store.commit(SET_ITEM_FOR_ACTION, {});

          credentials.forEach((credential) => {
            this.formData[credential.name] = credential.value;
          });
        }
      }
      this.dialog = !this.dialog;
    },
    resetFormData() {
      this.formData = {
        rcs_ip: null,
        rcs_port: null,
        wes_ip: null,
        wes_port: null,
        number_of_chutes: null,
        number_of_robots: null,
        mode: null,
        tile_type: null,
        tile_value: null,
        wcs_ip: null,
        wcs_port: null,
        wcs_print_ip: null,
        wcs_print_port: null,
      };
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);

      let payload = {
        ...this.formData,
      };

      if (this.code === "LSR") {
        delete payload.wcs_ip;
        delete payload.wcs_port;
        delete payload.wcs_print_ip;
        delete payload.wcs_print_port;
      } else {
        delete payload.rcs_ip;
        delete payload.rcs_port;
        delete payload.number_of_robots;
        delete payload.mode;
        delete payload.tile_type;
        delete payload.tile_value;
      }

      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(`/wes/connectors/${actionUrl}`, {
        ...payload,
        customer_id: this.$props.customer_id,
        connector_id: this.$props.connector_id,
      })
        .then(() => {
          this.$store.dispatch(GET_WES_CUSTOMERS);
          if (this.actionType) {
            SwalService.successMessage({
              html: SwalService.messages.updated(),
            });
          } else {
            SwalService.successMessage({
              html: SwalService.messages.added(),
            });
          }
          this.toggleModal();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return this.$t("update");
      else return this.$t("submit");
    },
    headerText: function () {
      if (this.actionType) return this.$t("edit-item");
      else return this.$t("add-new-item");
    },
    tile_types: function () {
      return [
        { text: this.$t('tray'), index: "tray" },
        { text: this.$t('conveyer'), index: "conveyer" },
      ];
    },

    rcs_ipErrors: function () {
      return this.handleFormValidation("rcs_ip", this);
    },
    rcs_portErrors: function () {
      return this.handleFormValidation("rcs_port", this);
    },
    wes_ipErrors: function () {
      return this.handleFormValidation("wes_ip", this);
    },
    wes_portErrors: function () {
      return this.handleFormValidation("wes_port", this);
    },
    number_of_chutesErrors: function () {
      return this.handleFormValidation("number_of_chutes", this);
    },
    number_of_robotsErrors: function () {
      return this.handleFormValidation("number_of_robots", this);
    },
    tile_typeErrors: function () {
      return this.handleFormValidation("tile_type", this);
    },
    tile_valueErrors: function () {
      return this.handleFormValidation("tile_value", this);
    },
    wcs_ipErrors: function () {
      return this.handleFormValidation("wcs_ip", this);
    },
    wcs_portErrors: function () {
      return this.handleFormValidation("wcs_port", this);
    },
    wcs_print_ipErrors: function () {
      return this.handleFormValidation("wcs_print_ip", this);
    },
    wcs_print_portErrors: function () {
      return this.handleFormValidation("wcs_print_port", this);
    },
  },
};
</script>
